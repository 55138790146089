export const initFacebook = () => {
    // @ts-ignore
    // eslint-disable-next-line no-extra-semi
    ;(function (f, b, e, v, n: any, t: any, s: any) {
        if (f.fbq) return
        n = f.fbq = function () {
            // eslint-disable-next-line prefer-rest-params,prefer-spread
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !1
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')

    window.fbq('init', process.env.VUE_APP_FACEBOOK_PIXEL)
    window.fbq('track', 'PageView')
}
