import { appSessionStorage, sessionStorageKey } from '@/utils/storage'

// Keep in sync with UnderwritingMetadata in aven_backend/src/experiments/getExperimentsWithMetadata.ts
export interface UnderwritingMetadata {
    ExperimentName: string
    MinAPR: number
    MaxAPR: number
    MaxLineSize: number
    BTFee: number
    MinBTFeePercent: number
    MaxBTFeePercent: number
    COFee: number
    MinCOFeePercent: number
    MaxCOFeePercent: number
    rateIndexShortName: string
    rateIndexFullName: string
    rateValuePercentage: string // e.g., 1.75%
    rateIndexEffectiveDate: string
    generatePrimeV2Variant: boolean
    generateHighBtCoVariant: boolean
    generateTermExtensionVariant: boolean
    teaserPromoEnabled?: boolean
    teaserPromoApr?: number
    teaserPromoDurationInMonth?: number
    cashbackRatio?: number
    shouldUpdateCashbackAfterDisableAutoPay?: boolean
    cashbackAfterDisableAutoPay?: number
    maxLoanAmount?: number
}

export const getUnderwritingMetadata = (): UnderwritingMetadata => {
    const data = JSON.parse(appSessionStorage.getItem(sessionStorageKey.underwritingMetaData))
    return {
        ExperimentName: data.ExperimentName,
        MinAPR: data.MinAPR,
        MaxAPR: data.MaxAPR,
        MaxLineSize: data.MaxLineSize,
        BTFee: data.BTFee,
        MinBTFeePercent: data.MinBTFeePercent,
        MaxBTFeePercent: data.MaxBTFeePercent,
        COFee: data.COFee,
        MinCOFeePercent: data.MinCOFeePercent,
        MaxCOFeePercent: data.MaxCOFeePercent,
        rateIndexShortName: data.rateIndexShortName,
        rateIndexFullName: data.rateIndexFullName,
        rateValuePercentage: data.rateValuePercentage,
        rateIndexEffectiveDate: data.rateIndexEffectiveDate,
        generatePrimeV2Variant: data.generatePrimeV2Variant,
        generateHighBtCoVariant: data.generateHighBtCoVariant,
        generateTermExtensionVariant: data.generateTermExtensionVariant,
        teaserPromoEnabled: data.teaserPromoEnabled,
        teaserPromoApr: data.teaserPromoApr,
        teaserPromoDurationInMonth: data.teaserPromoDurationInMonth,
        cashbackRatio: data.cashbackRatio,
        shouldUpdateCashbackAfterDisableAutoPay: data.shouldUpdateCashbackAfterDisableAutoPay,
        cashbackAfterDisableAutoPay: data.cashbackAfterDisableAutoPay,
        maxLoanAmount: data.maxLoanAmount,
    }
}

export const isUnderwritingMetadataReady = (): boolean => {
    const data = JSON.parse(appSessionStorage.getItem(sessionStorageKey.underwritingMetaData))
    return !!data
}
