import { logger } from '@/utils/logger'

export const toFormattedUSDStripTrailingZeroCents = (number: number) => {
    let stringNum = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(number)

    stringNum = stringNum.replace(/\.00$/, '')

    return stringNum
}

export const formatApr = (value: number) => {
    return `${parseFloat(String(value * 100)).toFixed(2)}%`
}

export default {
    methods: {
        toThousands(val: number) {
            return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        toFormattedUSD(number: number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(number)
        },
        toFormattedUSDStripTrailingZeroCents,
        toFormattedUSDNoCents(number: number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumSignificantDigits: 3,
            }).format(number)
        },
        toFormattedUSDKs(number: number) {
            return `$${number / 1000}K`
        },
        formatApr,
        toFormattedAprStripTrailingZerosAfterDecimalPoint(value: number) {
            let stringNum = parseFloat(String(value * 100)).toFixed(2)

            stringNum = stringNum.replace(/\.00$/, '')

            if (/\.\d0$/.test(stringNum)) {
                // match XX.X0 to drop the last 0
                return stringNum.slice(0, stringNum.length - 1) + '%'
            }
            return stringNum + '%'
        },
        formatCashBackPercentage(value: number) {
            return `${parseFloat(String(value * 100)).toFixed(1)}%`
        },
        // use lodash?
        camelCase: (value: string) => {
            if (!value) return ''

            const ret = value.charAt(0).toUpperCase() + value.substring(1).toLowerCase()
            logger.info(`value: ${value} camcelCase: ${ret}`)
            return ret
        },
    },
}
