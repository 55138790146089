export interface AutoLoanTerms {
    apr: number
    margin: number
    lineSize: number
    cashBack: number
    monthlyFee: number
    product: ProductCategory
    months: number
    monthlyPayment: number
    monthlySavings: number
    policyName: string
    contingencies: OfferContingency[]
}

enum ProductCategory {
    basic = 'basic',
    prime = 'prime',
}

enum OfferContingency {}

export interface Vehicle {
    vin?: string
    make?: string
    model?: string
    year?: number
    mileage?: number
    trim?: string
}

export interface AutoTradelineDetails {
    lenderName: string
    loanAmountRemaining: number
    currentMonthlyPayment: number
    monthsRemaining: number
}

export interface PersistedState {
    autoApplicantId: number | null
    autoApplicationId: number | null
    sessionId: string
    jwtTokens: any
    vin: string
    make: string
    model: string
    year: string | null
    trim: string
    mileage: string | null
    licensePlate: string
    licensePlateState: string
    hasLoan: boolean
    preQualOffers: AutoLoanTerms[] | null
    selectedPreQualOffer: AutoLoanTerms | null
    qualOffer: AutoLoanTerms | null
    offers: AutoLoanTerms[] | null
    autoTradelineDetails: AutoTradelineDetails[] | null
    currentMonthlyPayment: string | null
    loanAmountRemaining: string | null
    lenderName: string | null
    monthsRemaining: string | null
    softPullCreatedAfter: string | null
    returnToken2: string | null
    supportingDocument: [] | null
}

export const persistedStore = {
    state: {
        autoApplicantId: null,
        autoApplicationId: null,
        sessionId: '',
        jwtTokens: '',
        vin: '',
        make: '',
        model: '',
        year: null,
        trim: '',
        mileage: null,
        licensePlate: '',
        licensePlateState: '',
        hasLoan: false,
        preQualOffers: null,
        selectedPreQualOffer: null,
        qualOffer: null,
        offers: null,
        autoTradelineDetails: null,
        currentMonthlyPayment: null,
        loanAmountRemaining: null,
        lenderName: null,
        monthsRemaining: null,
        softPullCreatedAfter: null,
        returnToken2: null,
        supportingDocument: null,
    },
    mutations: {
        updateAutoApplicantId(state: any, payload: { autoApplicantId: number }) {
            state.autoApplicantId = payload.autoApplicantId
        },
        updateAutoApplicationId(state: any, payload: { autoApplicationId: number }) {
            state.autoApplicationId = payload.autoApplicationId
        },
        updateVehicle(state: any, payload: Vehicle) {
            state.vin = payload.vin
            state.make = payload.make
            state.model = payload.model
            state.year = payload.year
            state.mileage = payload.mileage
        },
        updateHasLoan(state: any, payload: { hasLoan: boolean }) {
            state.hasLoan = payload.hasLoan
        },
        updatePreQualOffers(state: any, payload: { preQualOffers: AutoLoanTerms[] | null }) {
            state.preQualOffers = payload.preQualOffers
        },
        updateSelectedPreQualOffer(state: any, payload: { selectedPreQualOffer: AutoLoanTerms | null }) {
            state.selectedPreQualOffer = payload.selectedPreQualOffer
        },
        updateQualOffer(state: any, payload: { qualOffer: AutoLoanTerms | null }) {
            state.qualOffer = payload.qualOffer
        },
        updateOffers(state: any, payload: { offers: AutoLoanTerms[] | null }) {
            state.offers = payload.offers
        },
        updateAutoTradelineDetails(state: any, payload: { autoTradelineDetails: AutoTradelineDetails[] | null }) {
            state.autoTradelineDetails = payload.autoTradelineDetails
        },
        updateCurrentMonthlyPayment(state: any, payload: { currentMonthlyPayment: number | null }) {
            state.currentMonthlyPayment = payload.currentMonthlyPayment ? String(payload.currentMonthlyPayment) : null
        },
        updateLoanAmountRemaining(state: any, payload: { loanAmountRemaining: number | null }) {
            state.loanAmountRemaining = payload.loanAmountRemaining ? String(payload.loanAmountRemaining) : null
        },
        updateLenderName(state: any, payload: { lenderName: string | null }) {
            state.lenderName = payload.lenderName
        },
        updateMonthsRemaining(state: any, payload: { monthsRemaining: number | null }) {
            state.monthsRemaining = payload.monthsRemaining ? String(payload.monthsRemaining) : null
        },
        updateSoftPullCreatedAfter(state: any, payload: { softPullCreatedAfter: string | null }) {
            state.softPullCreatedAfter = payload.softPullCreatedAfter
        },
        updateReturnToken2(state: any, payload: { returnToken2: string | null }) {
            state.returnToken2 = payload.returnToken2
        },
        updateSupportingDocument(state: any, payload: { supportingDocument: [] | null }) {
            state.supportingDocument = payload.supportingDocument
        },
    },
}
