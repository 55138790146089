export const initNextDoor = () => {
    // provided by nextdoor team
    // eslint-disable-next-line no-extra-semi
    ;(function (win, doc, sdk_url) {
        if (win.ndp) return
        const tr = (win.ndp = function () {
            // eslint-disable-next-line prefer-rest-params,prefer-spread
            tr.handleRequest ? tr.handleRequest.apply(tr, arguments) : tr.queue.push(arguments)
        } as any)
        tr.queue = []
        const s = 'script'
        const new_script_section = doc.createElement(s)
        new_script_section.async = !1
        new_script_section.src = sdk_url
        const insert_pos = doc.getElementsByTagName(s)[0]
        if (insert_pos.parentNode) {
            insert_pos.parentNode.insertBefore(new_script_section, insert_pos)
        }
    })(window, document, 'https://ads.nextdoor.com/public/pixel/ndp.js')
    window.ndp('init', process.env.VUE_APP_NEXTDOOR_PIXEL, {})
    window.ndp('track', 'PAGE_VIEW')
}
