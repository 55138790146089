/**
 * App Storage class
 * @description This will be responsible for storing data into the application.
 * Commonly, people use LocalStorage or SessionStorage. This is just a wrapper over them
 * to restrict the usage of Global window storage throughout the application
 * Default, this is just using the LocalStorage
 */
import assert from 'assert'
import { inspect } from '@/utils/logger'

// TODO Auto: after VueX is implemented for the aven_auto refinance origination flow, these will be the only values left in the sessionStorageKey object
// const sessionStorageKey = {
//     sessionId: 'sessionId',
//     phoneNumber: 'phoneNumber',
//     currentFlow: 'currentFlow',
//     locale: 'locale',
//     experimentName: 'experimentName',
//     jwtTokens: 'jwtTokens',
//     coApplicantJwtTokens: 'coApplicantJwtTokens',
//     sessionAccessJWT: 'sessionAccessJWT',
//     logRocketInitialized: 'logRocketInitialized',
//     logRocketUrl: 'logRocketUrl',
//     httpTimeout: 'httpTimeout',
// }

const sessionStorageKey = {
    basicInfo: 'basicInfo',
    sessionId: 'sessionId',
    inviteCode: 'inviteCode',
    pifInviteCode: 'pifInviteCode',
    currentFlow: 'currentFlow',
    locale: 'locale',
    applicantId: 'applicantId',
    loanApplicationId: 'loanApplicationId',
    addedCoApplicantOnFailure: 'addedCoApplicantOnFailure',
    experimentName: 'experimentName',
    underwritingMetaData: 'underwritingMetaData',
    creditCardMarketData: 'creditCardMarketData',
    phoneNumber: 'phoneNumber',
    dateOfBirth: 'dateOfBirth',
    applicantEmail: 'applicantEmail',
    coApplicantPhoneNumber: 'coApplicantPhoneNumber',
    phoneHash: 'phoneHash',
    plaidPublicToken: 'plaidPublicToken',
    plaidLinkToken: 'plaidLinkToken',
    institutionInfo: 'institutionInfo',
    jwtTokens: 'jwtTokens',
    coApplicantJwtTokens: 'coApplicantJwtTokens',
    applicationData: 'applicationData',
    statedUsage: 'statedUsage',
    statedIncome: 'statedIncome',
    coApplicantStatedIncome: 'coApplicantStatedIncome',
    sessionAccessJWT: 'sessionAccessJWT',
    notaryAccessJWT: 'notaryAccessJWT',
    firstName: 'firstName',
    lastName: 'lastName',
    coApplicantFirstName: 'coApplicantFirstName',
    coApplicantLastName: 'coApplicantLastName',
    isFirstLienPosition: 'isFirstLienPosition',
    employer: 'employer',
    employerEmail: 'employerEmail',
    jobTitle: 'jobTitle',
    coApplicantJobTitle: 'coApplicantJobTitle',
    startPagePath: 'startPagePath',
    clearStorageOnNavigation: 'clearStorageOnNavigation',
    identityQA: 'identityQA',
    verifiedKba: 'verifiedKba',
    logRocketInitialized: 'logRocketInitialized',
    logRocketUrl: 'logRocketUrl',
    inviteCodeRequired: 'inviteCodeRequired',
    creditOffer: 'creditOffer',
    preQualificationOffer: 'preQualificationOffer',
    incomeVerificationCompleted: 'incomeVerificationCompleted',
    incomeVerificationMethod: 'incomeVerificationMethod',
    otherIncomeLetterInfo: 'otherIncomeLetterInfo',
    form1099Info: 'form1099Info',
    w2StatementInfo: 'w2StatementInfo',
    preQualificationFailureCode: 'preQualificationFailureCode',
    payStubInfo: 'payStubInfo',
    bankStatementsInfo: 'bankStatementsInfo',
    taxReturnInfo: 'taxReturnInfo',
    alreadySubmittedHMDA: 'alreadySubmittedHMDA',
    applicantSubmittedEmployer: 'applicantSubmittedEmployer',
    coApplicantSubmittedEmployer: 'coApplicantSubmittedEmployer',
    applicantMaritalStatus: 'applicantMaritalStatus',
    coApplicantMaritalStatus: 'coApplicantMaritalStatus',
    priorApplicationFoundResponseJSON: 'priorApplicationFoundResponseJSON',
    addReview: 'addRating',
    isUnderwritingInfoUnchanged: 'isUnderwritingInfoUnchanged',
    landingWarning: 'landingWarning',
    hasExtendedLinesOffer: 'hasExtendedLinesOffer',
    sessionRecordingInitialized: 'sessionRecordingInitialized',
    sessionRecordingUrl: 'sessionRecordingUrl',
    experimentsOverrides: 'experimentsOverrides',

    // this overrides the timeout value for axios only used for cypress tests atm due to them being http/1.1 https://github.com/cypress-io/cypress/issues/3708
    httpTimeout: 'httpTimeout',

    // Flags
    trustsFeatureFlag: 'trustsFeatureFlag',
}

export class AppStorage {
    storage: Storage | undefined
    inMemoryStorage: Map<string, string>

    constructor(storage?: Storage) {
        this.storage = storage
        this.inMemoryStorage = new Map()
    }

    // window.storage only accepts string
    // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem
    setItem(key: string, value: string) {
        assert(typeof key === 'string', `AppStorage.setItem key must be a string`)
        assert(typeof value === 'string', `AppStorage.setItem value for ${key} must be a string`)
        this.storage?.setItem(key, value)
        this.inMemoryStorage.set(key, value)
    }

    /**
     * Value can be of any type, if its not a string user must provide a transformer function which returns a string
     * @param key {string}
     * @param value {any}
     * @param transformer {function}
     */
    setItemIfPresent(key: string, value: any, transformer?: (param: any) => string) {
        if (typeof value === 'undefined' || value === null) {
            console.log(`Skipping setting key ${key}`)
            return
        }

        assert(typeof value === 'string' || transformer, 'Value is not of type string and no transformer was provided')

        const finalValue = transformer ? transformer(value) : value
        return this.setItem(key, finalValue)
    }

    getItem(key: string): string {
        let value = this.inMemoryStorage.get(key)
        if (typeof value === 'undefined') {
            value = this.storage?.getItem(key) as string
            // Keep in sync inMemoryStorage
            if (value !== null) {
                console.log(`Value ${value} for ${key} found in browser storage but not in memory. Setting in memory`)
                this.inMemoryStorage.set(key, value)
            }
        }

        return value
    }

    removeItem(key: string) {
        this.inMemoryStorage.delete(key)
        this.storage?.removeItem(key)
    }

    clear() {
        this.inMemoryStorage.clear()
        this.storage?.clear()
    }

    /** @param exceptedKeyList string[] The keys we want to keep */
    clearWithException(exceptedKeyList: string[]) {
        const clearKeys: string[] = []
        Object.keys(this.getAll()).forEach((key) => {
            if (!exceptedKeyList.includes(key)) {
                clearKeys.push(key)
            }
        })
        clearKeys.forEach((key) => this.removeItem(key))
    }

    getAll(): { [key: string]: string } {
        const inMemoryStorageObj = Object.fromEntries(this.inMemoryStorage)
        const browserStorageObj = Object.assign({}, this.storage)
        console.log(`In memory storage contents: ${inspect(inMemoryStorageObj)}`)
        console.log(`Browser storage contents: ${inspect(browserStorageObj)}`)
        return Object.assign(browserStorageObj, inMemoryStorageObj)
    }
}

/**
 * Creating the instances of storage.
 */
const appLocalStorage = new AppStorage(window.localStorage)
const appSessionStorage = new AppStorage(window.sessionStorage)

export { appLocalStorage, appSessionStorage, sessionStorageKey }
