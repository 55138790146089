import { RouteConfig } from 'vue-router'

const routeName = 'shared'

const pageNames = {
    THANKS: 'thanks',
    PRIORITY_APPLICATION_REDIRECTION: 'redirect', // must fix references in aven_notary if this is changed
    PROMPT_CONTINUE_PRIOR_APPLICATION: 'select_application',
    CONFIRM_DISCARD_PRIOR_APPLICATION: 'discard_prior_application',
    LOAN_NOT_APPROVED: 'unapproved',
    UNABLE_TO_VERIFY_IDENTITY: 'unable_to_verify_identity',
    WAITLIST: 'waitlist',
    PAGE_NOT_FOUND: 'page_not_found',
}

export const sharedPagePaths = {
    THANKS: `/${routeName}/${pageNames.THANKS}`,
    PRIORITY_APPLICATION_REDIRECTION: `/${routeName}/${pageNames.PRIORITY_APPLICATION_REDIRECTION}`, // must fix references in aven_notary if this is changed
    PROMPT_CONTINUE_PRIOR_APPLICATION: `/${routeName}/${pageNames.PROMPT_CONTINUE_PRIOR_APPLICATION}`,
    CONFIRM_DISCARD_PRIOR_APPLICATION: `/${routeName}/${pageNames.CONFIRM_DISCARD_PRIOR_APPLICATION}`,
    LOAN_NOT_APPROVED: `/${routeName}/${pageNames.LOAN_NOT_APPROVED}`,
    UNABLE_TO_VERIFY_IDENTITY: `/${routeName}/${pageNames.UNABLE_TO_VERIFY_IDENTITY}`,
    WAITLIST: `/${routeName}/${pageNames.WAITLIST}`,
    PAGE_NOT_FOUND: `*`,
}

export const sharedRoutes: RouteConfig[] = [
    {
        path: sharedPagePaths.THANKS,
        component: () => import('@/pages/shared/Thanks.vue'),
        meta: { public: true },
    },
    {
        path: sharedPagePaths.PRIORITY_APPLICATION_REDIRECTION,
        component: () => import('@/pages/shared/PriorityApplicationRedirection.vue'),
        meta: { public: true },
    },
    {
        path: sharedPagePaths.PROMPT_CONTINUE_PRIOR_APPLICATION,
        component: () => import('@/pages/shared/PromptContinuePriorApplication.vue'),
        meta: { public: true },
    },
    {
        path: sharedPagePaths.CONFIRM_DISCARD_PRIOR_APPLICATION,
        component: () => import('@/pages/shared/ConfirmDiscardPriorApplication.vue'),
        meta: { public: true },
    },
    {
        path: sharedPagePaths.LOAN_NOT_APPROVED,
        component: () => import('@/pages/shared/Unapproved.vue'),
        meta: { public: true, transitionName: 'slide' },
    },
    {
        path: sharedPagePaths.UNABLE_TO_VERIFY_IDENTITY,
        component: () => import('@/pages/shared/UnableToVerifyIdentity.vue'),
        meta: { public: true },
    },
    {
        path: sharedPagePaths.WAITLIST,
        component: () => import('@/pages/shared/Waitlist.vue'),
        meta: { public: true },
    },
    {
        path: sharedPagePaths.PAGE_NOT_FOUND,
        component: () => import(/* webpackChunkName: "pageNotFound" */ '@/pages/shared/PageNotFound.vue'),
        meta: { public: true },
    },
]
