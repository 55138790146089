import { RouteConfig } from 'vue-router'

const routeName = 'auto'

const pageNames = {
    // Auto Pages
    VEHICLE_SEARCH_SELECTION: 'vehicle_search_selection',
    LICENSE_PLATE: 'license_plate',
    VEHICLE_MODEL: 'vehicle_model',
    VIN_INPUT: 'vin_input',
    CONFIRM_VEHICLE: 'confirm_vehicle',
    HAS_LOAN: 'has_loan',
    LOAN_SELECTION: 'loan_selection',
    GET_LOAN_DETAILS: 'get_loan_details',
    CONFIRM_LOAN_DETAILS: 'confirm_loan_details',
    REFI_SELECTION: 'refi_selection',
    PRE_QUALIFICATION: 'pre_qualification',
    INSURANCE_PROVIDER: 'insurance_provider',
    INSURANCE_UPLOAD: 'insurance_upload',
    INSURANCE_VERIFICATION: 'insurance_verification',
    DOCUMENT_REVIEW: 'document_review',
    QUALIFICATION: 'qualification',
    FORWARD_TO_NOTARIZATION: 'forward_to_notarization',

    // Deprecated
    PAYOFF_AMOUNT: 'payoff_amount',
    OFFERS: 'offers',

    // TODO Auto: deprecate unused pages
    CONFIRM_DOB: 'confirm_dob',
    STATED_USAGE: 'stated_usage',
    PERSONAL_INFO: 'personal_info',
    STATED_INCOME: 'stated_income',
    CO_OWNER: 'co_owner',
    CO_OWNER_WITH_CO_APPLICANT: 'joint_co_owner',
    EMPLOYER_EMAIL: 'employer_email',
    EMPLOYER_EMAIL_CODE_VERIFICATION: 'code_verification',
    EMPLOYER_EMAIL_PURGATORY: 'employer_email_verify',
    HMDA: 'hmda',
    OFFER_PREVIEW: 'offer_preview',
    VERIFY_IDENTITY: 'identity',
    CO_APPLICANT_VERIFY_IDENTITY: 'co_applicant_identity',
    IDENTITY_QUESTIONS: 'identity_questions',
    CO_APPLICANT_IDENTITY_QUESTIONS: 'co_applicant_identity_questions',
    EMPLOYMENT_INCOME_VERIFICATION: 'employment_income_verification',
    INCOME_VERIFICATION: 'income_verification',
    IDENTITY_VERIFICATION: 'identity_verification',
    CO_APPLICANT_EMPLOYMENT_INCOME_VERIFICATION: 'co_applicant_employment_income_verification',
    PAY_STUB_VERIFICATION: 'verify_pay_stubs',
    TAX_RETURN_VERIFICATION: 'verify_tax_return',
    BANK_STATEMENT_VERIFICATION: 'verify_bank_statement',
    OTHER_INCOME_VERIFICATION: 'verify_other_income',
    AWAITING_MANUAL_INCOME_VERIFICATION: 'awaiting_manual_income_verification',
    SIGN_DOCUMENTS: 'sign_documents',
    AWAITING_JOINT_INCOME_OFFER_CONTINGENCY: 'awaiting_joint_income_offer_contingency',
    REMEDY_NAME_MISMATCH: 'remedy_name_mismatch',
    SELECT_INCOME_VERIFICATION_METHOD: 'select_income_verification_method',
    PROVIDE_SUPPORTING_INFORMATION: 'supporting_information',
    EXPERIAN_FROZEN: 'credit_report_frozen',
    CO_APPLICANT_EXPERIAN_FROZEN: 'co_applicant_experian_frozen',
    OFFER_ADD_CO_APPLICANT_ON_FAILURE: 'offer_add_co_applicant_on_failure',
    ADD_CO_APPLICANT_INFO: 'add_co_applicant_information',
    SSN_VERIFICATION: 'ssn_verification',
    CO_APPLICANT_SSN_VERIFICATION: 'co_applicant_ssn_verification',
    TRUST_INFO_SUBMISSION: 'trust_info_submission',
    SIGN_TOGETHER: 'sign_together',
    WELCOME: 'welcome',
}

export const originationPagePaths = {
    // Auto Pages
    VEHICLE_SEARCH_SELECTION: `/${routeName}/${pageNames.VEHICLE_SEARCH_SELECTION}`,
    VEHICLE_MODEL: `/${routeName}/${pageNames.VEHICLE_MODEL}`,
    LICENSE_PLATE: `/${routeName}/${pageNames.LICENSE_PLATE}`,
    VIN_INPUT: `/${routeName}/${pageNames.VIN_INPUT}`,
    CONFIRM_VEHICLE: `/${routeName}/${pageNames.CONFIRM_VEHICLE}`,
    HAS_LOAN: `/${routeName}/${pageNames.HAS_LOAN}`,
    LOAN_SELECTION: `/${routeName}/${pageNames.LOAN_SELECTION}`,
    GET_LOAN_DETAILS: `/${routeName}/${pageNames.GET_LOAN_DETAILS}`,
    CONFIRM_LOAN_DETAILS: `/${routeName}/${pageNames.CONFIRM_LOAN_DETAILS}`,
    REFI_SELECTION: `/${routeName}/${pageNames.REFI_SELECTION}`,
    PRE_QUALIFICATION: `/${routeName}/${pageNames.PRE_QUALIFICATION}`,
    INSURANCE_PROVIDER: `/${routeName}/${pageNames.INSURANCE_PROVIDER}`,
    INSURANCE_UPLOAD: `/${routeName}/${pageNames.INSURANCE_UPLOAD}`,
    INSURANCE_VERIFICATION: `/${routeName}/${pageNames.INSURANCE_VERIFICATION}`,
    DOCUMENT_REVIEW: `/${routeName}/${pageNames.DOCUMENT_REVIEW}`,
    QUALIFICATION: `/${routeName}/${pageNames.QUALIFICATION}`,
    FORWARD_TO_NOTARIZATION: `/${routeName}/${pageNames.FORWARD_TO_NOTARIZATION}`,

    // Deprecated
    PAYOFF_AMOUNT: `/${routeName}/${pageNames.PAYOFF_AMOUNT}`,
    OFFERS: `/${routeName}/${pageNames.OFFERS}`,

    // TODO Auto: deprecate unused pages
    CONFIRM_DOB: `/${routeName}/${pageNames.CONFIRM_DOB}`,
    STATED_USAGE: `/${routeName}/${pageNames.STATED_USAGE}`,
    PERSONAL_INFO: `/${routeName}/${pageNames.PERSONAL_INFO}`,
    EMPLOYER_EMAIL: `/${routeName}/${pageNames.EMPLOYER_EMAIL}`,
    EMPLOYER_EMAIL_CODE_VERIFICATION: `/${routeName}/${pageNames.EMPLOYER_EMAIL_CODE_VERIFICATION}`,
    EMPLOYER_EMAIL_PURGATORY: `/${routeName}/${pageNames.EMPLOYER_EMAIL_PURGATORY}`,
    STATED_INCOME: `/${routeName}/${pageNames.STATED_INCOME}`,
    CO_OWNER: `/${routeName}/${pageNames.CO_OWNER}`,
    CO_OWNER_WITH_CO_APPLICANT: `/${routeName}/${pageNames.CO_OWNER_WITH_CO_APPLICANT}`,
    HMDA: `/${routeName}/${pageNames.HMDA}`,
    OFFER_PREVIEW: `/${routeName}/${pageNames.OFFER_PREVIEW}`,
    VERIFY_IDENTITY: `/${routeName}/${pageNames.VERIFY_IDENTITY}`,
    CO_APPLICANT_VERIFY_IDENTITY: `/${routeName}/${pageNames.CO_APPLICANT_VERIFY_IDENTITY}`,
    IDENTITY_QUESTIONS: `/${routeName}/${pageNames.IDENTITY_QUESTIONS}`,
    CO_APPLICANT_IDENTITY_QUESTIONS: `/${routeName}/${pageNames.CO_APPLICANT_IDENTITY_QUESTIONS}`,
    EMPLOYMENT_INCOME_VERIFICATION: `/${routeName}/${pageNames.EMPLOYMENT_INCOME_VERIFICATION}`,
    INCOME_VERIFICATION: `/${routeName}/${pageNames.INCOME_VERIFICATION}`,
    IDENTITY_VERIFICATION: `/${routeName}/${pageNames.IDENTITY_VERIFICATION}`,
    CO_APPLICANT_EMPLOYMENT_INCOME_VERIFICATION: `/${routeName}/${pageNames.CO_APPLICANT_EMPLOYMENT_INCOME_VERIFICATION}`,
    PAY_STUB_VERIFICATION: `/${routeName}/${pageNames.PAY_STUB_VERIFICATION}`,
    TAX_RETURN_VERIFICATION: `/${routeName}/${pageNames.TAX_RETURN_VERIFICATION}`,
    BANK_STATEMENT_VERIFICATION: `/${routeName}/${pageNames.BANK_STATEMENT_VERIFICATION}`,
    OTHER_INCOME_VERIFICATION: `/${routeName}/${pageNames.OTHER_INCOME_VERIFICATION}`,
    AWAITING_MANUAL_INCOME_VERIFICATION: `/${routeName}/${pageNames.AWAITING_MANUAL_INCOME_VERIFICATION}`,
    SIGN_DOCUMENTS: `/${routeName}/${pageNames.SIGN_DOCUMENTS}`,
    AWAITING_JOINT_INCOME_OFFER_CONTINGENCY: `/${routeName}/${pageNames.AWAITING_JOINT_INCOME_OFFER_CONTINGENCY}`,
    REMEDY_NAME_MISMATCH: `/${routeName}/${pageNames.REMEDY_NAME_MISMATCH}`,
    SELECT_INCOME_VERIFICATION_METHOD: `/${routeName}/${pageNames.SELECT_INCOME_VERIFICATION_METHOD}`,
    PROVIDE_SUPPORTING_INFORMATION: `/${routeName}/${pageNames.PROVIDE_SUPPORTING_INFORMATION}`,
    EXPERIAN_FROZEN: `/${routeName}/${pageNames.EXPERIAN_FROZEN}`,
    CO_APPLICANT_EXPERIAN_FROZEN: `/${routeName}/${pageNames.CO_APPLICANT_EXPERIAN_FROZEN}`,
    OFFER_ADD_CO_APPLICANT_ON_FAILURE: `/${routeName}/${pageNames.OFFER_ADD_CO_APPLICANT_ON_FAILURE}`,
    ADD_CO_APPLICANT_INFO: `/${routeName}/${pageNames.ADD_CO_APPLICANT_INFO}`,
    SSN_VERIFICATION: `/${routeName}/${pageNames.SSN_VERIFICATION}`,
    CO_APPLICANT_SSN_VERIFICATION: `/${routeName}/${pageNames.CO_APPLICANT_SSN_VERIFICATION}`,
    TRUST_INFO_SUBMISSION: `/${routeName}/${pageNames.TRUST_INFO_SUBMISSION}`,
    SIGN_TOGETHER: `/${routeName}/${pageNames.SIGN_TOGETHER}`,
    WELCOME: `/${routeName}/${pageNames.WELCOME}`,
}

const dataCollectionRoutes: RouteConfig[] = [
    {
        path: originationPagePaths.STATED_USAGE,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/StatedUsage.vue'),
        meta: { public: true },
    },
    {
        path: originationPagePaths.PERSONAL_INFO,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/PersonalInfo.vue'),
    },
    {
        path: originationPagePaths.SSN_VERIFICATION,
        component: () => import(/* webpackChunkName: "mailerOrigination" */ '@/pages/origination/SSNVerification.vue'),
    },
    {
        path: originationPagePaths.CO_APPLICANT_SSN_VERIFICATION,
        component: () => import(/* webpackChunkName: "mailerOrigination" */ '@/pages/origination/SSNVerification.vue'),
        props: { isCoApplicant: true },
    },
    {
        path: originationPagePaths.OFFER_ADD_CO_APPLICANT_ON_FAILURE,
        component: () => import('@/pages/origination/OfferAddCoApplicantOnFailure.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.ADD_CO_APPLICANT_INFO,
        component: () => import(/* webpackChunkName: "mailerOrigination" */ '@/pages/origination/AddCoApplicantInfo.vue'),
    },
    {
        path: originationPagePaths.CO_APPLICANT_VERIFY_IDENTITY,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/VerifyIdentity.vue'),
        props: { isCoApplicant: true },
    },
    {
        path: originationPagePaths.CO_APPLICANT_IDENTITY_QUESTIONS,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IdentityQuestions.vue'),
        props: { isCoApplicant: true },
    },
    {
        path: originationPagePaths.CO_APPLICANT_EXPERIAN_FROZEN,
        component: () => import(/* webpackChunkName: "coOrigination" */ '@/pages/origination/ExperianFrozen.vue'),
        meta: { transitionName: 'slide' },
        props: { isCoApplicant: true },
    },
    {
        path: originationPagePaths.EMPLOYER_EMAIL,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/EmployerEmail.vue'),
    },
    {
        path: originationPagePaths.EMPLOYER_EMAIL_CODE_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/EmployerEmailCodeVerification.vue'),
    },
    {
        path: originationPagePaths.EMPLOYER_EMAIL_PURGATORY,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/auth/EmployerEmailPurgatory.vue'),
    },
    {
        path: originationPagePaths.PAY_STUB_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/PayStubVerification.vue'),
    },
    {
        path: originationPagePaths.TAX_RETURN_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/TaxReturnVerification.vue'),
    },
    {
        path: originationPagePaths.BANK_STATEMENT_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/BankStatementUpload.vue'),
    },
    {
        path: originationPagePaths.OTHER_INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/OtherIncomeStatementUpload.vue'),
    },
    {
        path: originationPagePaths.STATED_INCOME,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/StatedIncome.vue'),
    },
    {
        path: originationPagePaths.EXPERIAN_FROZEN,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/ExperianFrozen.vue'),
    },
    {
        path: originationPagePaths.HMDA,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/HMDA.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.CO_OWNER,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/CoOwner.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.CO_OWNER_WITH_CO_APPLICANT,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/CoOwnerWithCoApplicant.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.SIGN_TOGETHER,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/SignTogether.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.VERIFY_IDENTITY,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/VerifyIdentity.vue'),
    },
    {
        path: originationPagePaths.IDENTITY_QUESTIONS,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IdentityQuestions.vue'),
    },
    {
        path: originationPagePaths.SELECT_INCOME_VERIFICATION_METHOD,
        redirect: () => {
            return { path: originationPagePaths.EMPLOYMENT_INCOME_VERIFICATION }
        },
    },
    {
        path: originationPagePaths.EMPLOYMENT_INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/EmploymentIncomeVerification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IncomeVerification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.IDENTITY_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IdentityVerification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.CO_APPLICANT_EMPLOYMENT_INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/EmploymentIncomeVerification.vue'),
        meta: { transitionName: 'slide' },
        props: { isCoApplicant: true },
    },
    {
        path: originationPagePaths.PROVIDE_SUPPORTING_INFORMATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/ProvideSupportingInformation.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.OFFER_PREVIEW,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/OfferPreview.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.AWAITING_MANUAL_INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/AwaitingManualIncomeVerification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.SIGN_DOCUMENTS,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/SignDocuments.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.AWAITING_JOINT_INCOME_OFFER_CONTINGENCY,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/AwaitingJointIncomeOfferContingency.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.REMEDY_NAME_MISMATCH,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/RemedyNameMismatch.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.TRUST_INFO_SUBMISSION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/TrustInfoSubmission.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.WELCOME,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/Welcome.vue'),
        meta: { transitionName: 'slide' },
    },
]

const autoRoutes: RouteConfig[] = [
    {
        path: originationPagePaths.VEHICLE_SEARCH_SELECTION,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/VehicleSearchSelection.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.LICENSE_PLATE,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/LicensePlate.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.VEHICLE_MODEL,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/VehicleModel.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.VIN_INPUT,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/VinInput.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.CONFIRM_VEHICLE,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/ConfirmVehicle.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.HAS_LOAN,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/HasLoan.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.LOAN_SELECTION,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/LoanSelection.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.GET_LOAN_DETAILS,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/GetLoanDetails.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.CONFIRM_LOAN_DETAILS,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/ConfirmLoanDetails.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.REFI_SELECTION,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/RefiSelection.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.PRE_QUALIFICATION,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/PreQualification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.INSURANCE_PROVIDER,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/InsuranceProvider.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.INSURANCE_VERIFICATION,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/InsuranceVerification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.INSURANCE_UPLOAD,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/InsuranceUpload.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.DOCUMENT_REVIEW,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/DocumentReview.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.QUALIFICATION,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/Qualification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.FORWARD_TO_NOTARIZATION,
        component: () => import(/* webpackChunkName: "autos" */ '@/pages/auto/ForwardToNotarization.vue'),
        meta: { transitionName: 'slide' },
    },
    // Deprecated
    {
        path: originationPagePaths.PAYOFF_AMOUNT,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/PayoffAmount.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.OFFERS,
        component: () => import(/* webpackChunkName: "auto" */ '@/pages/auto/Offers.vue'),
        meta: { transitionName: 'slide' },
    },
]

export const originationRoutes: RouteConfig[] = [...dataCollectionRoutes, ...autoRoutes]
