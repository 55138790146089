/**
 * To use, pick a returnToken2 & a ValidDocusignDocType, then construct a url
 * like: http://localhost:8090/docs/{docType}/{returnToken2}
 *
 * Alternatively, you can go to http://localhost:8090/docs/devDocReturn and
 * enter the returnToken + document type directly
 */

import { RouteConfig } from 'vue-router'

const routeName: string = 'docs'

const pageNames = {
    DOC_RETURN: 'docs',
    PUBLIC_DOCS: 'publicDocs',
    DOCS_UPLOAD: 'docsUpload',
    DEV_DOC_RETURN: 'devDocs',
}

export const docRoutePaths = {
    PUBLIC_DOC: `/public/${routeName}/:docType`,
    DOC_RETURN: `/${routeName}/:docType/:returnToken`,
    DOCS_UPLOAD: `/${routeName}_upload/:returnToken`,
    DEV_DOC_RETURN: `/${routeName}/devDocReturn`,
}

export const docsRoutes: RouteConfig[] = [
    {
        path: docRoutePaths.DOC_RETURN,
        name: pageNames.DOC_RETURN,
        component: () => import(/* webpackChunkName: "auto-jodl-docs" */ '@/pages/docs/JodlDocument.vue'),
        meta: { public: true },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: docRoutePaths.PUBLIC_DOC,
        name: pageNames.PUBLIC_DOCS,
        component: () => import(/* webpackChunkName: "auto-jodl-docs" */ '@/pages/docs/JodlDocument.vue'),
        meta: { public: true },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: docRoutePaths.DOCS_UPLOAD,
        name: pageNames.DOCS_UPLOAD,
        component: () => import(/* webpackChunkName: "auto-jodl-docs" */ '@/pages/docs/SupportingDocumentVerification.vue'),
        meta: { public: true },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
]

// Only respond to this route if it's local dev
if (process.env.VUE_APP_NODE_ENV === 'development') {
    docsRoutes.push({
        path: docRoutePaths.DEV_DOC_RETURN,
        name: pageNames.DEV_DOC_RETURN,
        component: () => import(/* webpackChunkName: "auto-jodl-docs" */ '@/pages/docs/JodlDocument.vue'),
        meta: { public: true },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    })
}
