import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { getField, updateField, createHelpers } from 'vuex-map-fields'
import { persistedStore, PersistedState } from '@/store/persisted'

Vue.use(Vuex)

export interface DefaultState {
    // New information
    insuranceCardInfo: [] | null

    // PersistedState
    persistedStore: PersistedState
}

const getDefaultState = () => {
    return {
        insuranceCardInfo: null,
    }
}

const persistedData = createPersistedState({
    paths: ['persistedStore'],
    storage: window.sessionStorage,
})

export const { mapFields: mapPersistedFields } = createHelpers({
    getterType: 'getPersistedField',
    mutationType: 'updatePersistedField',
})

export default new Vuex.Store({
    state: getDefaultState() as DefaultState,
    modules: { persistedStore },
    getters: {
        getField,
        getPersistedField(state) {
            return getField(state.persistedStore)
        },
    },
    mutations: {
        updateField,
        updatePersistedField(state, payload: any) {
            updateField(state.persistedStore, payload)
        },
        updateInsuranceCardInfo(state, payload: { insuranceCardInfo: [] }) {
            state.insuranceCardInfo = payload.insuranceCardInfo
        },
    },
    plugins: [persistedData],
})
