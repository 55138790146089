import { RouteConfig } from 'vue-router'
import { marketingPageNames, marketingPagePaths } from '@/routes/marketingRoutes'

const AutoLanding = () => import(/* webpackChunkName: "auto-landing" */ '@/experiments/src/pages/marketing/AutoLanding.vue')

const landingPageRoutes: RouteConfig[] = [
    {
        path: marketingPagePaths.LANDING,
        name: marketingPageNames.LANDING,
        component: AutoLanding,
        props: { codeRequired: false, showForgotCodeOption: true },
        meta: { public: true },
    },
]

export const experimentMarketingPageRoutes: RouteConfig[] = [...landingPageRoutes]
